import React, { useEffect } from 'react';

const ClientBridge = () => {
  useEffect(() => {
    // Optionally add a delay
    setTimeout(() => {
      // Call your serverless function here
      fetch('/.netlify/functions/wakix-prescription-referral-form')
        .then(response => {
          if (response.ok) {
            window.location.href = '/.netlify/functions/wakix-prescription-referral-form';
          }
        })
        .catch(error => {
          // Handle the error here
        });
    }, 100); // 100ms delay
  }, []);

  return (
    <div>
      <p>Loading, please wait...</p>
    </div>
  );
};

export default ClientBridge;
